<template>
	<section class="portfolio-overview center">
		<div style="width:100%; max-width:1000px;">
			<quote-title label="系列作品" />
			<br>
			<div class="center">
				<router-link to="/manager/portfolio/new">
					<el-button type="success" icon="el-icon-plus" round>新增</el-button>
				</router-link>
			</div>
			<br>
			<div class="row-grid" gutter-x="20" gutter-y="10" align-x="center">
				<div v-for="(item, i) in typeList" :key="item + i">
					<radio v-model="currentType" :id="item + i" :value="item" />
				</div>
			</div>
			<br>
			<br>
			<transition name="fade" mode="out-in">
				<div v-if="fadeVisible" class="row-grid" gutter-x="30" gutter-y="20">
					<template v-for="(item, i) in list">
						<router-link :to="`/manager/portfolio/${item.id}`" class="col rwd-l-3 rwd-m-4 rwd-s-6" :key="item.id || i">
							<thumb :src="item.thumb" />
							<br>
							<div class="col" gutter="10">
								<p style="text-align:center;">{{item.title}}</p>
								<small style="text-align:center;">{{item.sub_title}}</small>
							</div>
							<br>
						</router-link>
					</template>
				</div>
			</transition>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Thumb: () => import("@/components/thumb"),
		Radio: () => import("@/components/radio"),
	},
	data() {
		return {
			fadeVisible: true,
			currentType: ""
		}
	},
	computed: {
		...mapState("portfolio", ["_portfolioList"]),
		typeList() {
			return this._portfolioList
				.reduce((a, { type }) => type && a.includes(type) ? a : a.concat(type), new Array);
		},
		list() {
			return this._portfolioList.filter(({ type }) => type == this.currentType);
		}
	},
	watch: {
		currentType(val) {
			this.fadeVisible = false;
			setTimeout(() => (this.fadeVisible = true), 300);
		}
	},
	methods: {
		...mapActions("portfolio", ["_getList"]),
	},
	async created() {
		await this._getList();
		const { type } = this._portfolioList
			.find(({ type }) => type) || new Object;
		this.currentType = type || "";
	}
}
</script>